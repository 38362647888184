import {
  BellIcon,
  CalendarIcon,
  UserAddIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import moment from "moment";
import { Component } from "react";
import AgentAnalyticsModel from "../../models/AgentAnalytics";
import {
  analyticDataLimit,
  getRecentAnalytics,
  handleDownloadAnalyticCSV,
} from "../../store/actions/analyticsActions";
import Button from "../base/Button";
import Table, { TableHeader } from "../base/Table";
import UpdateJerryCanModal from "../dashboard/UpdateJerryCanModal";

interface State {
  analytics: AgentAnalyticsModel;
  pageIndex: number;
  exportLoading: boolean;
}

interface Props {
  redeemJerryCanModal: boolean;
  handleUpdateRedeemJerryCanModal: (visible: boolean) => void;
}

class Home extends Component<Props> {
  state: State = {
    analytics: {
      id: "",
      userId: "",
      redeemedUser: 0,
      recyclingUser: 0,
      appointmentMade: 0,
      ucoCollected: 0,
      monthlyUcoCollected: 0,
      addressAnalytics: [],
      createdDate: new Date(),
    },
    pageIndex: 1,
    exportLoading: false,
  };

  componentDidMount = async () => {
    const analytics = await getRecentAnalytics();
    if (analytics && typeof analytics !== "string") {
      this.setState({
        analytics,
      });
    }
  };

  handleUpdatePageIndex = (pageIndex: number) => {
    this.setState({ pageIndex });
  };

  handleExportCSV = async () => {
    this.setState({ exportLoading: true });
    try {
      const getAnalyticCSV = await handleDownloadAnalyticCSV();
      if (getAnalyticCSV) window.open(getAnalyticCSV);
      else throw new Error("Error");
    } catch (err) {
      window.alert("Download fail. Please try again.");
    }
    this.setState({ exportLoading: false });
  };

  handlePagination = (value: string) => {
    if (value === "next") {
      this.handleUpdatePageIndex(this.state.pageIndex + 1);
    } else {
      this.handleUpdatePageIndex(this.state.pageIndex - 1);
    }
  };

  renderDashboardContent = () => {
    let updatedDate = moment().format("DD MMMM YYYY ha");
    if (this.state.analytics.id) {
      updatedDate = moment(
        this.state.analytics.createdDate.seconds * 1000
      ).format("DD MMMM YYYY ha");
    }
    const content = [
      {
        icon: <UserGroupIcon className="h-5 w-5 text-white" />,
        title: "Redeemed Users",
        content: this.state.analytics.redeemedUser,
        span: `Updated On : ${updatedDate}`,
      },
      {
        icon: <UserAddIcon className="h-5 w-5 text-white" />,
        title: "Recycling Users",
        content: this.state.analytics.recyclingUser,
        span: `Updated On : ${updatedDate}`,
      },
      {
        icon: <CalendarIcon className="h-5 w-5 text-white" />,
        title: "Appointments Made",
        content: this.state.analytics.appointmentMade,
        span: `Updated On : ${updatedDate}`,
      },
      {
        icon: <BellIcon className="h-5 w-5 text-white" />,
        title: "Used Cooking Oil Collected(kg)",
        content: this.state.analytics.ucoCollected.toFixed(2),
        span: `Updated On : ${updatedDate}`,
      },
      {
        icon: <BellIcon className="h-5 w-5 text-white" />,
        title: "Carbon Credit Collected",
        content: Number(this.state.analytics.ucoCollected * 3.17).toFixed(2),
        span: `Updated On : ${updatedDate}`,
      },
    ];
    const contentViewList: any = [];
    content.map((eachContent, key: number) => {
      contentViewList.push(
        <div className="bg-white overflow-hidden shadow rounded-lg" key={key}>
          <div className="p-5">
            <div className="flex">
              <div className="flex-shrink-0">
                <span className="flex p-2 mt-1 rounded-lg bg-arusgreen-600">
                  {eachContent.icon}
                </span>
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-md font-medium text-gray-500 truncate">
                    {eachContent.title}
                  </dt>
                  <dd>
                    <p className="text-xs font-medium text-gray-500 truncate">
                      {eachContent.span}
                    </p>
                    <div className={`text-lg font-medium text-gray-900 mt-2`}>
                      {eachContent.content}
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      );
      return null;
    });
    return contentViewList;
  };

  renderTopAddresses = () => {
    const tableData: any[] = [];

    if (this.state.analytics.addressAnalytics.length > 0) {
      this.state.analytics.addressAnalytics.map(
        (eachAddressAnalytics, index) => {
          tableData.push({
            id: index,
            name: eachAddressAnalytics.name,
            address: eachAddressAnalytics.address,
            amount: eachAddressAnalytics.amount,
            date: moment(eachAddressAnalytics.date.seconds * 1000).format(
              "DD MMMM YY"
            ),
          });
          return null;
        }
      );
    }
    return tableData;
  };

  render() {
    const headers: TableHeader[] = [
      {
        key: "name",
        title: "Name",
      },
      {
        key: "address",
        title: "Address",
      },
      {
        key: "amount",
        title: "Amount(KG)",
      },
      {
        key: "date",
        title: "Date",
      },
    ];

    return (
      <>
        <UpdateJerryCanModal
          open={this.props.redeemJerryCanModal}
          onClose={this.props.handleUpdateRedeemJerryCanModal.bind(this, false)}
        />
        <div className="mt-8">
          <div className="max-w-6xl mx-auto mt-5 px-4 sm:px-6 lg:px-8">
            <h2 className="text-lg leading-6 font-medium text-gray-900">
              Overview
            </h2>
            <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2">
              {this.renderDashboardContent()}
            </div>
          </div>
          <div className="max-w-6xl flex flex-col  mx-auto mt-5 px-4 sm:items-center sm:px-6 sm:flex-row lg:px-8">
            <h2 className="text-lg leading-6 font-medium text-gray-900">
              View All Collections
            </h2>
            <div className="flex-grow" />
            {!this.state.exportLoading && (
              <Button
                className="w-full mt-3 sm:mt-0 sm:w-auto"
                text="Export CSV"
                type="normal"
                onClick={() => this.handleExportCSV()}
              />
            )}
          </div>

          <Table
            pagination={true}
            pageIndex={this.state.pageIndex}
            loading={false}
            lastCursor={""}
            className="mt-4"
            header={headers}
            data={this.renderTopAddresses()}
            dataLimit={analyticDataLimit}
            handlePage={this.handlePagination}
          />
        </div>
      </>
    );
  }
}

export default Home;
