import axios from "axios";
import moment from "moment";
import AgentAnalytics from "../../models/AgentAnalytics";
import firebase from "../../utils/firebase";

export const analyticDataLimit: number = 10;

export const getRecentAnalytics = async () => {
  try {
    if (firebase.auth().currentUser?.uid) {
      const analyticsSnapshot = await firebase
        .firestore()
        .collection("agentAnalytics")
        .orderBy("createdDate", "desc")
        .where("userId", "==", firebase.auth().currentUser?.uid)
        .limit(1)
        .get();

      let selectedAnalytics = null;
      if (analyticsSnapshot) {
        analyticsSnapshot.forEach((eachAnalytics) => {
          selectedAnalytics = eachAnalytics.data();
        });
      }

      if (selectedAnalytics) {
        return selectedAnalytics;
      } else {
        return "No data";
      }
    } else {
      return "Unknown error, please contact developer at info@arusoil.com if this continues";
    }
  } catch (err) {
    return "Unknown error, please contact developer at info@arusoil.com if this continues";
  }
};

export const handleDownloadAnalyticCSV = async () => {
  try {
    const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
    const agentAnalyticAPILoc = isProd ? "arusoil-web" : "arusoil-web-dev";
    const agentAnalyticAPI = `https://asia-southeast2-${agentAnalyticAPILoc}.cloudfunctions.net/getAnalyticCSV`;

    const agentAnalyticRes = await axios.post(agentAnalyticAPI, {
      agentId: firebase.auth().currentUser?.uid,
      token: process.env.REACT_APP_ADMIN_TOKEN,
    });

    return agentAnalyticRes?.data?.link;
  } catch (err) {
    return "";
  }
};

export const getMonthsAnalytics = async () => {
  try {
    const selectedDateAnalytics: AgentAnalytics[] = [];
    if (firebase.auth().currentUser?.uid) {
      const todayDate = moment().endOf("month");
      const currentDates: Date[] = [];
      currentDates.push(todayDate.toDate());
      for (let i = 1; i < 6; i++) {
        const subtractDate = moment(todayDate).subtract(i, "month");
        currentDates.push(subtractDate.toDate());
      }

      await Promise.all(
        currentDates.map(async (eachDate) => {
          const analyticsSnapshot = await firebase
            .firestore()
            .collection("agentAnalytics")
            .where("userId", "==", firebase.auth().currentUser?.uid)
            .where(
              "createdDate",
              ">",
              moment(eachDate).startOf("month").toDate()
            )
            .where("createdDate", "<=", eachDate)
            .orderBy("createdDate", "desc")
            .limit(1)
            .get();
          let selectedAnalytics = null;
          if (analyticsSnapshot) {
            analyticsSnapshot.forEach((eachAnalytics) => {
              selectedAnalytics = eachAnalytics.data() as AgentAnalytics;
              selectedAnalytics.createdDate = new Date(
                selectedAnalytics.createdDate.seconds * 1000
              );
              selectedDateAnalytics.push(selectedAnalytics);
            });
          }
        })
      );

      selectedDateAnalytics.sort(function (a, b: AgentAnalytics) {
        return b.createdDate.getTime() - a.createdDate.getTime();
      });

      return selectedDateAnalytics;
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
};
